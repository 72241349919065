import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch, } from 'react-router-dom';
import './assets/scss/index.scss';
import { getUrlParams } from './helpers/helperFunctions';

const Error404 = lazy(() => import('./components/Error404'));
const ScoreBoard = lazy(() => import('./components/ScoreBoard'));

const App = () => {
    useEffect(() => {
        if(process.env.NODE_ENV !== 'development') {
            document.documentElement.classList.add('production-mode');
        }
    }, []);

    useEffect(() => {
        const {color} = getUrlParams();
        document.documentElement.style.setProperty('--hero-color', `${color ? '#' + color : '#ff319f'}`);
    }, []);

    return (
        <Suspense fallback={null}>
            <BrowserRouter>
                <Switch>
                    <Route exact path={'/'}><Error404/></Route>
                    <Route exact path={'/board/:gameId'}><ScoreBoard/> </Route>
                    <Redirect to="/"/>
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
};
export default App;
